import { inject } from '@angular/core';
import { filter, firstValueFrom, switchMap, take } from 'rxjs';
import { AppState } from '../../store/app.state';
import { UserState, UserType } from '../../store/user.state';
import { AppService } from '../../services/app.service';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { CanActivateFn } from '@angular/router';
import { ProfileState } from '../../store/profile.state';
import { AuthenticationModalPage } from '../../modals/authentication/authentication-modal.page';

export const authenticationPageGuard: CanActivateFn = async () => {
  const appState = inject(AppState);
  const userState = inject(UserState);
  const appService = inject(AppService);
  const modalController = inject(ModalController);
  const nav = inject(NavController);
  const profileState = inject(ProfileState);

  const user = await firstValueFrom(
    appState.appReady$.pipe(
      filter(appReady => appReady),
      take(1),
      switchMap(() => userState.user$),
    ),
  );

  if (user.userType === UserType.MOYA) {
    void nav.navigateRoot('home');
    return false;
  }

  const params = new URL(window.location.toString()).searchParams;
  const jwt = params.get('jwt');
  const uuid = params.get('uuid');
  let componentProps = null;

  // If the url is the confirmation email url, we check that the jwt match the logged in jwt
  if (window.location.href.includes('/confirmation-email')) {
    if (jwt && matchJwt(jwt, userState.user$.value.id)) {
      componentProps = { confirmEmailToken: jwt };
    } else {
      void nav.navigateRoot('home');
      return false;
    }
  }

  if (window.location.href.includes('/reset-password') && jwt && uuid) {
    componentProps = {
      resetPasswordData: {
        jwt,
        uuid,
      },
    };
  }

  if (appService.isMobile$.value) {
    return true;
  } else {
    // Open authentication modal
    void nav.navigateRoot('/home');
    const loginCreateAccountModal = await modalController.create({
      component: AuthenticationModalPage,
      cssClass: 'wr-modal authentication',
      componentProps: componentProps || {},
    });

    await loginCreateAccountModal.present();

    // Force go to profile creation
    await loginCreateAccountModal.onDidDismiss().then(() => {
      if (userState.user$.value.userType !== UserType.GUEST && !profileState.currentProfile$.value) {
        void nav.navigateRoot(profileState.profiles$.value.length > 1 ? '/profile/select' : '/profile/create', {
          state: { hideHeader: true },
        });
      }
    });

    return false;
  }
};

const matchJwt = (urlJwt: string, storedUserId: string): boolean => {
  try {
    const urlUserId = JSON.parse(atob(urlJwt.split('.')[1]))?.userId;

    if (!storedUserId) {
      return false;
    }

    return urlUserId === storedUserId;
  } catch (error) {
    return false;
  }
};
