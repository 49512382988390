import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'translateFallback',
  standalone: true,
})
export class TranslateFallback implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) {}

  transform(translationKey: string, defaultValue: string): Observable<string> {
    return this.languageService.initialized$.pipe(map(initialized => (initialized ? this.translateService.instant(translationKey) : defaultValue)));
  }
}
