import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { IonRadio, IonRadioGroup, ModalController } from '@ionic/angular/standalone';
import { ButtonComponent } from '../../../../components/button/button.component';
import { Router } from '@angular/router';
import { BookShelfService } from '../../../../services/book-shelf.service';
import { LanguageService } from '../../../../services/language.service';
import { BookCategory } from '../../../../models/book.model';
import { Translatable } from '../../../../models/translation.model';
import { filter, take } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-select-category-desktop-modal',
  templateUrl: './select-category-desktop-modal.page.html',
  styleUrls: ['./select-category-desktop-modal.page.scss'],
  standalone: true,
  imports: [TranslateModule, AsyncPipe, ButtonComponent, IonRadio, IonRadioGroup],
})
export class SelectCategoryDesktopModalPage implements OnInit {
  constructor(
    public bookShelfService: BookShelfService,
    public languageService: LanguageService,
    private analyticsService: AnalyticsService,
    private modalController: ModalController,
    private router: Router,
    private ref: ChangeDetectorRef,
  ) {}

  selectedCategory?: BookCategory;

  ngOnInit(): void {
    this.bookShelfService.categoriesBooks$
      .pipe(
        filter(categories => categories.length > 0),
        take(1),
      )
      .subscribe(categories => {
        console.log(categories);
        this.selectedCategory = categories[0] || null;
        this.ref.detectChanges();
      });
  }

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.SelectCategoryModal });
  }

  async onSelectedCategoryChange(event: CustomEvent): Promise<void> {
    this.selectedCategory = event.detail.value;
  }

  async applyCategoryChange(): Promise<void> {
    if (this.selectedCategory) {
      void this.router.navigate(['shelf/category/' + this.selectedCategory.id]);
      void this.modalController.dismiss();
    }

    void this.modalController.dismiss();
  }

  closeModal(): void {
    void this.modalController.dismiss();
  }

  backendTranslate(translatable: Translatable): string {
    return this.languageService.translateBackendCopy(translatable);
  }
}
