import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Injectable({ providedIn: 'root' })
export class DeepLinkService {
  constructor(
    private router: Router,
    private zone: NgZone,
  ) {}

  initialize(): void {
    void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = new URL(event.url).pathname;
        if (url) {
          void this.router.navigateByUrl(url);
        }
      });
    });
  }
}
