import { CanActivateFn, Router } from '@angular/router';
import { AppState } from '../store/app.state';
import { inject } from '@angular/core';
import { filter, firstValueFrom, switchMap } from 'rxjs';
import { UserState, UserType } from '../store/user.state';

export const guestGuard: CanActivateFn = async () => {
  const appState = inject(AppState);
  const router = inject(Router);
  const userState = inject(UserState);

  const user = await firstValueFrom(
    appState.appReady$.pipe(
      filter(appReady => appReady),
      switchMap(() => userState.user$),
    ),
  );

  if (user.userType === UserType.GUEST) {
    void router.navigate(['home']);
    return false;
  }

  return true;
};
