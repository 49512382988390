import { Injectable, OnDestroy } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { filter, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { UserState } from '../store/user.state';
import { HttpClientService } from '../services/http-client.service';
import { AppState } from '../store/app.state';

@Injectable({ providedIn: 'root' })
export class HeadersInterceptor implements HttpInterceptor, OnDestroy {
  constructor(
    private userState: UserState,
    private appState: AppState,
    private httpClientService: HttpClientService,
  ) {
    this.appState.stateReady$
      .pipe(
        filter(stateReady => stateReady),
        switchMap(() => this.userState.user$),
        takeUntil(this.destroyed$),
      )
      .subscribe(user => {
        this.jwt = user.jwt;
      });
  }

  private destroyed$ = new Subject<void>();

  private jwt: string = '';

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = this.httpClientService.generateHeaders(this.getRequestParameters(request.url));

    // If the request already has a Authorization header, we don't add it again
    // (Token is provided by provider in case of password reset for instance
    request = request.clone({
      setHeaders: {
        'X-worldreader-hash': headers.hash,
        'X-worldreader-client': headers.client,
        'X-worldreader-timestamp': headers.timestamp,
        ...(request.headers.get('Authorization') ? {} : { Authorization: this.jwt }),
      },
    });

    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getRequestParameters(url: string): string {
    const segments = url.replace('https://', '').split('/');
    segments.shift();
    return '/' + segments.join('/');
  }
}
