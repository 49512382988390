<div class="modal-container">
  <div class="left-side">
    <div class="close-modal" (click)="closeModal()">
      <img src="assets/icon/blue-close.svg" alt="" />
    </div>
    <div class="app-logo">
      <img src="assets/icons/icon-512.webp" alt="" />
    </div>
    <div>
      <div class="title">{{ 'PWA_banner_mobile_installApp_header' | translate }}</div>
      <div class="subtitle">{{ 'PWA_banner_mobile_installApp_subtitle' | translate }}</div>
    </div>
  </div>

  <div>
    <app-button [size]="buttonSize" [label]="'PWA_banner_mobile_installApp_CTA_install' | translate" (clicked)="openNativeStore()" />
  </div>
</div>
