import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeOut } from '../../utils/animations';
import { IonSpinner, Platform } from '@ionic/angular/standalone';
import { SplashScreenService } from '../../services/splash-screen.service';
import { AsyncPipe } from '@angular/common';
import { TranslateFallback } from '../../pipes/translate-with-fallback.pipe';
import { ButtonComponent } from '../button/button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss',
  animations: [fadeOut],
  standalone: true,
  imports: [AsyncPipe, TranslateFallback, ButtonComponent, IonSpinner],
})
export class SplashScreenComponent {
  constructor(
    private splashScreenService: SplashScreenService,
    private platform: Platform,
  ) {}

  readonly String = String;

  isLoading = false;
  isWeb = !this.platform.is('cordova');
  displayWebSplashScreen$ = this.splashScreenService.displayWebSplashScreen$;
  displayErrorSplashScreen$ = this.splashScreenService.displayErrorSplashScreen$;
  displaySplashNetworkError = this.splashScreenService.displaySplashNetworkError;

  reloadApp(): void {
    this.isLoading = true;
    location.reload();
  }

  help(): void {
    window.open('mailto:product@worldreader.org', '_blank');
  }
}
