@if (appService.isMobile$ | async) {
  <div class="vroom-card-mobile" data-cy="home-vroom-card" (click)="goToVroomTips()">
    <div class="left">
      <div class="title">{{ 'PWA_readTogetherLibrary_header_ParentTips' | translate }}</div>
      <app-button (clicked)="goToVroomTips()" [label]="'PWA_readTogetherLibrary_ParentTips_banner_CTA_letsGo' | translate" data-cy="home-vroom-card-btn"/>
    </div>
    <div class="right">
      <div  class="logo">
        <img src="assets/imgs/vroom/vroom-logo-white.png" alt="" />
      </div>
      <div class="bottom">
        <img src="assets/imgs/vroom/vroom-readers.svg" alt="" class="arabic-transform" />
      </div>
    </div>
  </div>
} @else {
  <div class="vroom-card-desktop" data-cy="home-vroom-card" (click)="goToVroomTips()">
    <div class="left">
      <div>
        <img src="assets/imgs/vroom/vroom-logo-white.png" alt="" />
      </div>
      <div>
        <div class="title">{{ 'PWA_readTogetherLibrary_header_ParentTips' | translate }}</div>
        <div class="subtitle">{{ 'PWA_desktop_readTogetherLibrary_subtitle_ParentTips' | translate }}</div>
      </div>

    </div>

    <div class="center">
      <app-button (clicked)="goToVroomTips()" [label]="'PWA_readTogetherLibrary_ParentTips_banner_CTA_letsGo' | translate" data-cy="home-vroom-card-btn"/>
    </div>

    <div class="right">
      <img src="assets/imgs/vroom/vroom-readers.svg" alt="" class="arabic-transform" />
    </div>
  </div>
}
