<app-header [showBackButton]="false" />
<ion-content>
  <div class="main-container app-content-max-width">
    <div class="filters-row">
      <app-search (searchFocused)="searchFocused($event)" />
    </div>
    <div [class.hidden]="isSearchFocused" class="shelves" data-cy="home-shelves-container">
      @if ((profileState.currentProfile$ | async)?.id) {
        <app-book-shelf [type]="shelfType.ContinueReading" data-cy="home-continue-reading-shelf" />
      }

      <app-book-shelf [type]="shelfType.FeaturedActive" data-cy="home-featured-active-shelf" />
      <app-book-shelf [type]="shelfType.Collection" data-cy="home-collection-shelf" />

      @if ((profileState.currentProfile$ | async)?.isFamilyProfile === true) {
        <app-book-shelf [type]="shelfType.BedStory" [new]="true" data-cy="home-bed-story-shelf" />
      }

      @if (shouldDisplayTips$ | async) {
        <app-tips />
      }

      <app-book-shelf [type]="shelfType.Popular" data-cy="home-popular-shelf" />
      <app-book-shelf [type]="shelfType.New" data-cy="home-new-shelf" />
      <app-book-categories-shelves data-cy="home-categories-shelf" />
    </div>
  </div>
</ion-content>
