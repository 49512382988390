<!-- We need fallback values here, because the translateService might not be available if offline, or if the api has an error -->
@if (isWeb && (displayWebSplashScreen$ | async)) {
  <div class="splash-screen" [@fadeOut]>
    <img src="assets/logo/booksmart-logo.svg" />

    <ion-spinner name="circular"></ion-spinner>
  </div>
} @else if (displayErrorSplashScreen$ | async) {
  <div class="splash-screen" [@fadeOut] data-cy="splash-screen-error">
    <div class="error">
      {{
        'PWA_appStartup_error_failedStart'
          | translateFallback: 'Oops, we couldn\’t start up the app. Please try again later or contact support.'
          | async
      }}
    </div>
    <app-button
      [label]="String('PWA_appStartup_error_CTA_tryAgain' | translateFallback: 'Try again' | async)"
      (clicked)="reloadApp()"
      [isLoading]="isLoading" />

    <div class="how-to-fix-container">
      <div class="how-to-fix">
        {{ 'PWA_appStartup_error_troubleshooting_title' | translateFallback: 'How to fix this issue if the error persists:' | async }}
      </div>
      <div>- {{ 'PWA_appStartup_error_troubleshooting_1' | translateFallback: 'Check your network connection' | async }}</div>
      @if (isWeb) {
        <div>- {{ 'PWA_appStartup_error_troubleshooting_2' | translateFallback: 'Clean your browser cache' | async }}</div>
      } @else {
        <div>- {{ 'PWA_appStartup_error_troubleshooting_3' | translateFallback: 'Reinstall the BookSmart app' | async }}</div>
      }
      <div class="help" (click)="help()">- {{ 'PWA_appStartup_error_troubleshooting_4' | translateFallback: 'Contact support' | async }}</div>
    </div>
  </div>
} @else if (displaySplashNetworkError | async) {
  <div class="splash-screen" [@fadeOut]>
    <div class="error">
      {{
        'PWA_offline_error_checkConnection' | translateFallback: "Oops, it looks like you're offline. Check your connection and try again." | async
      }}
    </div>
    <div class="spinner-container">
      <ion-spinner name="circular"></ion-spinner>
    </div>
  </div>
}
