import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_KEY_SEARCH } from './constants';

export interface Search {
  history: Array<string>;
}

@Injectable({ providedIn: 'root' })
export class SearchState {
  constructor(private preferenceService: PreferencesService) {}

  private readonly STORAGE_KEY = STORAGE_KEY_SEARCH;

  search$ = new BehaviorSubject<Search>({ history: [] });

  async initialize(): Promise<void> {
    const storedSearch = await this.get();
    storedSearch ? this.search$.next(storedSearch) : await this.set(this.search$.value);
  }

  private async get(): Promise<Search | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<Search>): Promise<void> {
    this.search$.next({ ...this.search$.value, ...settings });
    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(this.search$.value));
  }
}
