<ion-app appIsMobile #container [class.rtl]="languageService.arabicMode$ | async">
  @if (appState.appReady$ | async) {
    @if ((appService.isMobile$ | async) === false) {
      <ion-menu contentId="main-content" type="overlay" [swipeGesture]="false" [side]="(languageService.arabicMode$ | async) ? 'end' : 'start'">
        <app-menu [dir]="(languageService.arabicMode$ | async) ? 'rtl' : 'rlt'"></app-menu>
      </ion-menu>
    }

    <ion-router-outlet id="main-content" [dir]="(languageService.arabicMode$ | async) ? 'rtl' : 'rlt'"></ion-router-outlet>
  }
  <app-splash-screen> </app-splash-screen>
</ion-app>
