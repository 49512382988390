import { ChangeDetectorRef, Directive, HostListener } from '@angular/core';
import { AppService } from '../services/app.service';

/**
 * This directive is only used once in the app component in order to get the current resolution of the app
 * And update the app.service isMobile BehaviourSubject
 */

@Directive({ standalone: true, selector: '[appIsMobile]' })
export class IsMobileResolutionDirective {
  constructor(
    private appService: AppService,
    private ref: ChangeDetectorRef,
  ) {}

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.appService.updateIsMobileView(window.innerWidth);
    this.ref.detectChanges();
  }
}
