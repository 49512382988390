import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { AppService, PlatformType } from './app.service';
import { NativeAppInstallationBannerComponent } from '../components/native-app-installation-prompt/native-app-installation-banner.page';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserState, UserType } from '../store/user.state';

@Injectable({ providedIn: 'root' })
export class BannerService {
  constructor(
    private appService: AppService,
    private router: Router,
    private userState: UserState,
  ) {}

  initialized$ = new BehaviorSubject(false);

  private rootContainer?: ViewContainerRef;
  private componentRef?: ComponentRef<NativeAppInstallationBannerComponent>;

  initialize(rootContainer: ViewContainerRef): void {
    this.rootContainer = rootContainer;

    this.showOptionalAppInstallationBanner();
  }

  private showOptionalAppInstallationBanner(): void {
    if (
      this.appService.platformType === PlatformType.WebMobile &&
      !['dev', 'qa', 'local'].includes(environment.envName) &&
      this.userState.user$.value.userType !== UserType.MOYA
    ) {
      if (!this.rootContainer) {
        console.error('ViewContainerRef is not set.');
        return;
      }

      this.componentRef = this.rootContainer.createComponent(NativeAppInstallationBannerComponent);

      // Auto close the banner on navigation change
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          take(1),
        )
        .subscribe(() => this.destroyOptionalAppInstallationBanner());
    }
  }

  destroyOptionalAppInstallationBanner(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
