import { inject } from '@angular/core';
import { firstValueFrom, switchMap, take } from 'rxjs';
import { CanActivateFn, Router } from '@angular/router';
import { ProfileState } from '../../store/profile.state';

export const tipsGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const profileState = inject(ProfileState);

  const currentProfile = await firstValueFrom(
    profileState.currentProfile$.pipe(
      take(1),
      switchMap(() => profileState.currentProfile$),
    ),
  );

  if (!currentProfile?.isFamilyProfile) {
    void router.navigate(['home']);
    return false;
  }

  return true;
};
