import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppService } from '../../../../services/app.service';
import { Router } from '@angular/router';
import { ButtonComponent } from '../../../../components/button/button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
  standalone: true,
  imports: [AsyncPipe, TranslateModule, ButtonComponent],
})
export class TipsComponent {
  constructor(
    public appService: AppService,
    private router: Router,
  ) {}

  goToVroomTips(): void {
    void this.router.navigate(['/tips']);
  }
}
